import React from "react"
import Navbar from "./Navbar"
import { Link, graphql, useStaticQuery } from "gatsby"
import "../styles/global.css"
import logo from "../images/logo.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebook,
  faInstagram,
  faFacebookMessenger,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

import { faCircle } from "@fortawesome/free-solid-svg-icons"
import { OutboundLink } from "gatsby-plugin-google-analytics"

export default function MainLayout({ children }) {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          contactWhatsapp
          contactEmail
          facebookUrl
          instagramUrl
        }
      }
    }
  `)

  const {
    contactWhatsapp,
    contactEmail,
    facebookUrl,
    instagramUrl,
  } = data.site.siteMetadata
  const emailUrl = contactEmail + "?subject=Información cursos (desde Website)"

  return (
    <>
      <Navbar />
      <div className="floating-icons">
        <FontAwesomeIcon icon={faCircle} className="floating-circle" />
        <OutboundLink target="_new" href={`https://wa.me/${contactWhatsapp}`}>
          <FontAwesomeIcon icon={faWhatsapp} className="floating-wa" />
        </OutboundLink>
      </div>
      <div className="content">{children}</div>
      <div className="footer">
        <footer className="grid layout">
          <div className="footer-logo">
            {/* <h2>Linguolab</h2> */}
            <img src={logo} className="footer-img" alt="logo" />
            <OutboundLink href={`mailto:${emailUrl}`}>
              info@linguolab.com
            </OutboundLink>
            <p>Copyright 2022 Linguolab</p>
          </div>
          <div className="footer-location">
            <h3>¿Más información?</h3>
            <Link to="/blogs" style={{textDecoration: 'underline'}}>Visita nuestro Blog</Link>
          </div>
          <div className="footer-help">
            <h3>Contacto</h3>
            <Link to="/contact" style={{textDecoration: 'underline'}}>Contáctanos</Link>
          </div>
          <div className="footer-contact">
            <h3>Redes Sociales</h3>
            <div className="footer-social">
              <OutboundLink target="_new" href={facebookUrl}>
                <FontAwesomeIcon icon={faFacebook} className="social-icon" />
              </OutboundLink>
              <OutboundLink target="_new" href={instagramUrl}>
                <FontAwesomeIcon icon={faInstagram} className="social-icon" />
              </OutboundLink>
              <OutboundLink target="_new" href="https://m.me/linguolabcol">
                <FontAwesomeIcon
                  icon={faFacebookMessenger}
                  className="social-icon"
                />
              </OutboundLink>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
